import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentCommunicationFeedbackModalComponent } from '../assignment-communication-feedback-modal/assignment-communication-feedback-modal.component';

@Component({
  selector: 'app-assignment-communication-feedback',
  templateUrl: './assignment-communication-feedback.component.html',
  styleUrl: './assignment-communication-feedback.component.scss'
})
export class AssignmentCommunicationFeedbackComponent {
  @Input({required: true}) assignmentId!: number;

  constructor(private bsModalService: BsModalService) { }

  public openModal(): void {
    this.bsModalService.show(AssignmentCommunicationFeedbackModalComponent, {
      initialState: {
        assignmentId: this.assignmentId
      }
    });
  }

}
