import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AssignmentsFilterModel } from '../models/assignments-filter.model';
import { AssignmentStateService } from '../services/assignment-state.service';
import { AssignmentsFilterStateService } from '../services/assignments-filter-state.service';

@Component({
  selector: 'app-filter-display',
  templateUrl: './filter-display.component.html',
  styleUrl: './filter-display.component.scss'
})
export class FilterDisplayComponent implements OnInit, OnDestroy {
  @Input() filterModelSubject?: BehaviorSubject<AssignmentsFilterModel | null>;

  private filterModelSubjectChanged?: Subscription;

  constructor(
    private assignmentStateService: AssignmentStateService,
    public assignmentsFilterStateService: AssignmentsFilterStateService
  ) { }

  ngOnDestroy(): void {
    if (this.filterModelSubjectChanged) this.filterModelSubjectChanged.unsubscribe();
  }

  ngOnInit(): void {
    if (this.filterModelSubject) {
      this.subscribeToFilterModelChanges();
    }
  }

  public resetFilterDisplayItems(): void {
    this.assignmentsFilterStateService.resetAllItems();
    this.resetFilterModel();
  }

  private resetFilterModel(): void {
    const defaultFilterModel = this.assignmentStateService.setAssignmentFilterDefault();
    this.filterModelSubject?.next(defaultFilterModel);
  }


  private subscribeToFilterModelChanges(): void {
    this.filterModelSubjectChanged = this.filterModelSubject?.subscribe((filterModel) => {
      this.assignmentsFilterStateService.updateFilterDisplay(filterModel);
      this.assignmentsFilterStateService.updateIsAnyFilterDisplayed(filterModel);
    });
  }
}
