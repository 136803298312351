import { Component, Input, OnInit } from '@angular/core';
import { AssignmentsCommunicationStateService } from '../../services/assignments-communication-state-service';

@Component({
  selector: 'app-assignment-communication',
  templateUrl: './assignment-communication.component.html',
  styleUrl: './assignment-communication.component.scss'
})
export class AssignmentCommunicationComponent implements OnInit {
  @Input({required: true}) assignmentId!: number;
  
  constructor(
    protected assignmentCommunicationStateService: AssignmentsCommunicationStateService
  ) { }

  ngOnInit(): void {
    this.assignmentCommunicationStateService.loadAssignmentCommunication(this.assignmentId);
  }
}
