<div *ngIf="(assignmentDetailsStateService.assignmentDetails | async)" class="d-flex flex-column gap-2">
  <!-- Header Row -->
  <div class="date-item gap-3">
    <!-- Date column with fixed width -->
    <div class="date-item-date">
      <label class="fw-bold">{{'assignments.courseDates.date' | transloco}}</label>
    </div>
    <!-- Flexible space for location -->
    <div>
      <label class="fw-bold">{{'assignments.courseDates.location' | transloco}}</label>
    </div>
  </div>

  <!-- Data Rows -->
  <div *ngFor="let date of (assignmentDetailsStateService.assignmentDetails | async)?.dates; let i = index"
    class="date-item gap-3 align-items-center">
    <!-- Date Column -->
    <div class="date-item-date">
      <!-- Different Start and End Dates -->
      <ng-container *ngIf="!IsOnSameDay(date)">
        <div class="d-flex flex-column">
          <!-- Start Date and Time -->
          <div class="lh-1">
            {{ date.from | date: "dd.MM.YY | HH.mm" }}
          </div>

          <!-- End Date and Time -->
          <div class="lh-1">
            {{ date.to | date: "dd.MM.YY | HH.mm" }}
          </div>
        </div>
      </ng-container>

      <!-- Single Date and Time -->
      <ng-container *ngIf="IsOnSameDay(date)">
        <div class="d-flex flex-row gap-2 align-items-center">
          <!-- Date Section -->
          <div class="text-center lh-1">
            {{ date.from | date: 'dd.MM.YY' }}
          </div>
          <div class="lh-1">
            |
          </div>
          <!-- Time Section -->
          <div>
            <div class="lh-1">{{ date.from | date: 'HH.mm' }}</div>
            <div class="lh-1">{{ date.to | date: 'HH.mm' }}</div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Location Column -->
    <div class="flex-grow-1">
      <div *ngFor="let location of date?.locations" class="lh-1">
        {{ location.facility }} | {{ location.room }}
      </div>
    </div>
  </div>
</div>