import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentCommunicationNotesModalComponent } from '../assignment-communication-notes-modal/assignment-communication-notes-modal.component';
import { AssignmentsCommunicationStateService } from '../../../services/assignments-communication-state-service';

@Component({
  selector: 'app-assignment-communication-notes',
  templateUrl: './assignment-communication-notes.component.html',
  styleUrl: './assignment-communication-notes.component.scss'
})
export class AssignmentCommunicationNotesComponent {
  @Input({required: true}) assignmentId!: number;

  constructor(
    private bsModalService: BsModalService,
    protected assignmentCommunicationStateService: AssignmentsCommunicationStateService,
  ) { }

  public openModal(): void {
    this.bsModalService.show(AssignmentCommunicationNotesModalComponent, {
      initialState: {
        assignmentId: this.assignmentId
      }
    });
  }
}
