import { Component } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
    templateUrl: './assignments-page.component.html',
    styleUrl: './assignments-page.component.scss',
})
export class AssignmentsPageComponent {
    constructor(
        private headerStateService: HeaderStateService,
        private translocoService: TranslocoService
    ) {
        this.headerStateService.setDetailHeader(
            this.translocoService.translate('main.header.assignmentsTitle'),
            this.translocoService.translate('main.header.assignmentsDescription'))
    }
}
