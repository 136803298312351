import { Component, OnInit } from '@angular/core';
import { AssignmentParticipantsStateService } from '../../services/assignments-participants-state.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AssignmentParticipantModel } from '../../models/assignment-participant.model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-assignment-sportskills-modal',
  templateUrl: './assignment-sportskills-modal.component.html',
  styleUrl: './assignment-sportskills-modal.component.scss'
})
export class AssignmentSportskillsModalComponent implements OnInit {
  skillsForm!: FormGroup;
  // ToDo: The skills have to be dynamically loaded for each course, this is a mock for the time being
  skills: string[] = ['Skill 1', 'Skill 2', 'Skill 3'];
  skillKeys: number[] = [];


  constructor(
    protected assignmentParticipantStateService: AssignmentParticipantsStateService,
    private formBuilder: FormBuilder,
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.skillKeys = Object.keys(this.skills).map(key => +key); // Compute keys as numbers
    this.initializeForm();
    this.loadParticipantSkills();
  }

  get participantsFormArray(): FormArray {
    return this.skillsForm.get('participants') as FormArray; // Helper getter
  }

  saveForm(): void {
    this.bsModalRef.hide();
    // ToDo: Send data to abckend to update participant skills
  }

  public selectAll(skill: string): void {
    const formArray = this.skillsForm.get('participants') as FormArray;
    formArray.controls.forEach((group) => {
      group.get(skill)?.setValue(true);
    });
  }

  private initializeForm(): void {
    this.skillsForm = this.formBuilder.group({
      participants: this.formBuilder.array([]),
    });
  }

  private loadParticipantSkills(): void {
    this.assignmentParticipantStateService.assignmentParticipantsSubject.subscribe(
      (participants) => {
        if (participants) {
          this.populateParticipants(participants);
        }
      }
    );
  }

  private populateParticipants(participants: AssignmentParticipantModel[]): void {
    const participantsArray = this.participantsFormArray;
    participantsArray.clear();

    participants.forEach((participant) => {
      participantsArray.push(this.createParticipantFormGroup(participant));
    });
  }

  private createParticipantFormGroup(participant: AssignmentParticipantModel): FormGroup {
    return this.formBuilder.group({
      skillColumn1: [false],
      skillColumn2: [false],
      skillColumn3: [false],
      participantId: [participant.id],
    });
  }

}
