<pmt-action-modal
  [title]="'assignments.information.cancel' | transloco"
  [submitButtonIconCode]="''"
  [submitButtonText]="'modal.sendButton' | transloco"
  (submitted)="cancelAssignment()"
>
  <form [formGroup]="cancellationForm">
    <div class="alert alert-danger">
      <p>{{'assignments.information.cancellationAlert' | transloco }}</p>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" formControlName="isCancellationConfirmed" id="isCancellationConfirmed">
        <label class="form-check-label" for="isCancellationConfirmed">
          {{'assignments.information.cancellationConfirm' | transloco }}
        </label>
      </div>
    </div>
    <div class="d-flex flex-column gap-3">
      <div>
        <label for="cancellationReason">
          {{ "assignments.information.cancellationReason" | transloco }}
        </label>
        <textarea
          id="cancellationReason"
          class="form-control"
          formControlName="cancellationReason"
          rows="3"
        ></textarea>
      </div>
    </div>
    <ng-container *ngIf="errorMessage">
      <p class="text-danger">{{errorMessage}}</p>
    </ng-container>
  </form>
</pmt-action-modal>
