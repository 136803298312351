<!-- Loading -->
<ng-container *ngIf="(assignmentParticipantStateService.assignmentParticipantsSubject| async) == null">
  <div class="text-center">
    <span><i class="fa-solid fa-spinner fa-spin"></i></span>
  </div>
</ng-container>

<ng-container *ngIf="(assignmentParticipantStateService.assignmentParticipantsSubject| async) != null">
  <div class="d-flex flex-column gap-0">
    <app-assignment-participants-list></app-assignment-participants-list>
    <app-assignment-contact-participants
      [assignmentId]="assignmentId"
    ></app-assignment-contact-participants>
  </div>
</ng-container>

