import { Injectable } from "@angular/core";
import { BehaviorSubject, filter, map } from "rxjs";
import { AssignmentsOverviewModel } from "../models/assignments-overview.model";
import { AssignmentsService } from "./assignments.service";
import { AssignmentsFilterModel } from "../models/assignments-filter.model";
import { AssignmentChangeSubstituteResultModel } from "../models/assignment-change-substitute-result.model";

@Injectable({
    providedIn: 'root'
})

export class AssignmentStateService {
    // assignment lists
    public topThreeUpcomingAssignmentSubjects = new BehaviorSubject<AssignmentsOverviewModel[] | null>(null);
    public topThreeOpenAssignmentSubjects = new BehaviorSubject<AssignmentsOverviewModel[] | null>(null);
    public upcomingAssignmentsSubject = new BehaviorSubject<AssignmentsOverviewModel[] | null>(null);
    public openAssignmentsSubject = new BehaviorSubject<AssignmentsOverviewModel[] | null>(null);
    public pastAssignmentsSubject = new BehaviorSubject<AssignmentsOverviewModel[] | null>(null);

    // assignment filters
    public upcomingAssignmentsFilterSubject = new BehaviorSubject<AssignmentsFilterModel | null>(null);
    public exchangeAssignmentsFilterSubject = new BehaviorSubject<AssignmentsFilterModel | null>(null);
    public openAssignmentsFilterSubject = new BehaviorSubject<AssignmentsFilterModel | null>(null);
    public pastAssignmentsFilterSubject = new BehaviorSubject<AssignmentsFilterModel | null>(null);
    public changeAssignmentTrainerSubject = new BehaviorSubject<AssignmentChangeSubstituteResultModel[] | null>(null);

    constructor(private assignmentService: AssignmentsService) {
        this.initializeFilterListeners();
    }

    public loadUpcomingAssignments(filterModel: AssignmentsFilterModel | null): void {
        this.upcomingAssignmentsSubject.next(null);

        this.assignmentService.getUpcomingAssignments(filterModel).pipe(
            map(assignments => this.sortAssignmentsChronologically(assignments))
        ).subscribe({
            next: assignment => {
                this.upcomingAssignmentsSubject.next(assignment);
            }
        })
    }

    public loadOpenAssignments(filterModel: AssignmentsFilterModel | null): void {
        this.openAssignmentsSubject.next(null);

        this.assignmentService.getOpenAssignments(filterModel).pipe(
            map(assignments => this.sortAssignmentsChronologically(assignments))
        ).subscribe({
            next: assignment => {
                this.openAssignmentsSubject.next(assignment);
            }
        })
    }

    public loadPastAssignments(filterModel: AssignmentsFilterModel | null): void {
        this.pastAssignmentsSubject.next(null);

        this.assignmentService.getPastAssignments(filterModel).pipe(
            map(assignments => this.sortAssignmentsChronologically(assignments))
        ).subscribe({
            next: assignment => {
                this.pastAssignmentsSubject.next(assignment);
            }
        })

    }

    public loadtopThreeNextAssignments(): void {
        this.topThreeUpcomingAssignmentSubjects.next(null);

        this.assignmentService.getTopThreeNextAssignments().pipe(
            map(assignments => this.sortAssignmentsChronologically(assignments))
        ).subscribe({
            next: assignment => {
                this.topThreeUpcomingAssignmentSubjects.next(assignment);
            }
        })

    }

    public loadtopThreeOpenAssignments(): void {
        this.topThreeOpenAssignmentSubjects.next(null);

        this.assignmentService.getTopThreeOpenAssignments().pipe(
            map(assignments => this.sortAssignmentsChronologically(assignments))
        ).subscribe({
            next: assignment => {
                this.topThreeOpenAssignmentSubjects.next(assignment);
            }
        })

    }

    public initializeFilterListeners(): void {
        this.upcomingAssignmentsFilterSubject.subscribe((filterModel) => {
            if (filterModel) this.loadUpcomingAssignments(filterModel);
        });

        this.openAssignmentsFilterSubject.subscribe((filterModel) => {
            if (filterModel) this.loadOpenAssignments(filterModel);
        });

        this.pastAssignmentsFilterSubject.subscribe((filterModel) => {
            if (filterModel) this.loadPastAssignments(filterModel);
        });

        this.exchangeAssignmentsFilterSubject.subscribe((filterModel) => {
            if (filterModel) this.loadUpcomingAssignments(filterModel);
        })
    }


    public setAssignmentFilterDefault(): AssignmentsFilterModel {
        return {
            dateFrom: "",
            dateTo: "",
            searchText: "",
            skillLevel: "",
            weekdays: {
                isMondayActive: true,
                isTuesdayActive: true,
                isWednesdayActive: true,
                isThursdayActive: true,
                isFridayActive: true,
                isSaturdayActive: true,
                isSundayActive: true,
            },
            isWeekdayFilterActive: false
        }
    }

    private sortAssignmentsChronologically(assignments: AssignmentsOverviewModel[]): AssignmentsOverviewModel[] {
        return assignments.sort((a, b) => {
            const dateA = new Date(a.from!).getTime();
            const dateB = new Date(b.from!).getTime();

            // Sort start time first
            const startDateComparison = dateA - dateB;
            if (startDateComparison !== 0) {
                return startDateComparison;
            }

            // If start dates equal sort by end time
            const endA = new Date(a.to!).getTime();
            const endB = new Date(b.to!).getTime();
            return endA - endB;
        });
    }
}
