<pmt-action-modal
  [title]="'assignments.participants.contactParticipants' | transloco"
  [submitButtonText]="'modal.sendButton' | transloco"
  [submitButtonIconCode]="''"
  (submitted)="saveForm()"
>
  <form [formGroup]="participantContactForm">
    <div class="d-flex flex-column gap-2 form-group">
      <div>
        <label for="subject">{{'assignments.participants.mailSubject' | transloco}}</label>
        <div class="input-group-vertical">
          <span class="input-group-text" id="subject">{{subjectText}}</span>
          <input id="subject" class="form-control" formControlName="subject" type="text" style="border-top: none;">
        </div>
      </div>
      <div class="form-group">
        <label for="message">{{'assignments.participants.mailMessage' | transloco}}</label>
        <div >
          <quill-editor 
          class="editor w-100" 
          [modules]="editorModule" 
          id="message" 
          formControlName="message"
          placeholder="{{'assignments.participants.editorPlaceholder' | transloco}}"
          ></quill-editor>
        </div>
      </div>
      <div class="form-check">
        <input formControlName="sendCopy" class="form-check-input" type="checkbox" id="sendCopy">
        <label class="form-check-label" for="sendCopy"> {{'assignments.participants.mailSendCopy' | transloco}}</label>
      </div>
    </div>
  </form>
</pmt-action-modal>
