<!-- Loading -->
<ng-container *ngIf="(assignmentCommunicationStateService.assignmentCommunicationSubject| async) == null">
  <div class="text-center">
    <span><i class="fa-solid fa-spinner fa-spin"></i></span>
  </div>
</ng-container>

<ng-container *ngIf="(assignmentCommunicationStateService.assignmentCommunicationSubject| async) != null">
  <div class="d-flex flex-column gap-3">
    <app-assignment-communication-notes [assignmentId]="assignmentId"  ></app-assignment-communication-notes>
    <app-assignment-communication-update [assignmentId]="assignmentId"></app-assignment-communication-update>
    <app-assignment-communication-feedback [assignmentId]="assignmentId" ></app-assignment-communication-feedback>
  </div>
</ng-container>
