import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentCommunicationUpdateModalComponent } from '../assignment-communication-update-modal/assignment-communication-update-modal.component';
import { AssignmentsCommunicationStateService } from '../../../services/assignments-communication-state-service';

@Component({
  selector: 'app-assignment-communication-update',
  templateUrl: './assignment-communication-update.component.html',
  styleUrl: './assignment-communication-update.component.scss'
})
export class AssignmentCommunicationUpdateComponent {
  @Input({required: true}) assignmentId!: number;

  constructor(
    private bsModalService: BsModalService,
    protected assignmentCommunicationStateService: AssignmentsCommunicationStateService,
  ) { }

  public openModal(): void {
    this.bsModalService.show(AssignmentCommunicationUpdateModalComponent, {
      initialState: {
        assignmentId: this.assignmentId
      }
    });
  }
}
