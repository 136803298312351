<div class="d-flex flex-column gap-3">
  <button
    (click)="openTakeoverModal()"
    class="btn btn-primary"
    [disabled]="selectedAssignments?.length === 0"
    >
    {{selectedAssignments?.length! > 0 ? selectedAssignments?.length : 0}}
    Einsätze übernehmen
  </button>
  <app-filter-display
  [filterModelSubject]="assignmentsStateService.openAssignmentsFilterSubject">
  </app-filter-display>
  <app-assignments-list
    [eventType]="assignmentEventType.Checkbox"
    [assignmentSubject]="assignmentStateService.openAssignmentsSubject"
    (assignmentsSelected)="onAssignmentSelected($event)"
  ></app-assignments-list>
</div>
