import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AssignmentsCommunicationService } from "./assignments-communication.service";
import { AssignmentCommunicationModel } from "../models/assignment-communication.model";

@Injectable({
    providedIn: 'root'
})

export class AssignmentsCommunicationStateService {
    public assignmentCommunicationSubject = new BehaviorSubject<AssignmentCommunicationModel | null>(null);

    constructor(private assignmentCommunicationService: AssignmentsCommunicationService) { }

    public loadAssignmentCommunication(assignmentId: number): void {
        this.assignmentCommunicationSubject.next(null);
        
        this.assignmentCommunicationService.getAssignmentCommunication(assignmentId).subscribe({
            next: assignmentCommunication => {
                this.assignmentCommunicationSubject.next(assignmentCommunication);
            },
            error: (error) => {
            }
        })
    }
}