import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AssignmentDetailsModel } from '../models/assignment-details.model';

@Injectable({
    providedIn: 'root',
})
export class CourseDetailsService {

    constructor(private httpClient: HttpClient) { }

    getAssignmentDetails(assignmentId: number): Observable<AssignmentDetailsModel> {
        return this.httpClient.get<AssignmentDetailsModel>(`${environment.api}/assignments/${assignmentId}`);
    }
}
