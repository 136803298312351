<!-- Loading -->
<ng-container *ngIf="(assignmentDetailsStateService.assignmentDetails | async) == null">
  <div class="text-center">
    <span><i class="fa-solid fa-spinner fa-spin"></i></span>
  </div>
</ng-container>

<ng-container *ngIf="(assignmentDetailsStateService.assignmentDetails | async) != null">
  <div class="d-flex flex-column gap-1 p-1">
    <!-- Header Information -->
    <div>
      <div>
        <label class="fw-bold">{{'assignments.information.eventNumber' | transloco}}</label>
        <p>{{ (assignmentDetailsStateService.assignmentDetails | async)?.number }}</p>
      </div>
      <div>
        <label>{{'assignments.information.courseTitle' | transloco}}</label>
        <p>{{ (assignmentDetailsStateService.assignmentDetails | async)?.title }}</p>
      </div>
      <div>
        <label>{{'assignments.information.sportType' | transloco}}</label>
        <p>{{ (assignmentDetailsStateService.assignmentDetails | async)?.sport }}</p>
      </div>
    </div>
  
    <!-- Date and Location -->
    <div class="d-flex flex-column gap-1">
      <div class="date-item gap-3">
        <!-- Column Headers -->
        <div class="date-column-width">
          <label>{{'assignments.courseDates.date' | transloco}}</label>
        </div>
        <div class="flex-grow-1">
          <label>{{'assignments.courseDates.location' | transloco}}</label>
        </div>
      </div>
  
      <!-- Date and Time Details -->
      <div class="date-item gap-3 align-items-center">
        <!-- Date -->
        <div class="date-item-date">
          <!-- Different Start and End Dates -->
          <ng-container *ngIf="!IsOnSameDay(assignmentDetailsStateService.assignmentDetails | async)">
            <div class="d-flex flex-column">
              <!-- Start Date and Time -->
              <div class="lh-1">
                {{ (assignmentDetailsStateService.assignmentDetails | async)?.from | date: "dd.MM.YY | HH:mm" }}
              </div>
              <!-- End Date and Time -->
              <div class="lh-1">
                {{ (assignmentDetailsStateService.assignmentDetails | async)?.to | date: "dd.MM.YY | HH:mm" }}
              </div>
            </div>
          </ng-container>
  
          <!-- Single Date and Time -->
          <ng-container *ngIf="IsOnSameDay(assignmentDetailsStateService.assignmentDetails | async)">
            <div class="d-flex flex-row gap-1 align-items-center">
              <!-- Date Section -->
              <div class="lh-1">
                {{ (assignmentDetailsStateService.assignmentDetails | async)?.to | date: "dd.MM.YY" }}
              </div>
              <div>
                |
              </div>
              <!-- Time Section -->
              <div class="text-end">
                <div class="lh-1">{{ (assignmentDetailsStateService.assignmentDetails | async)?.from | date: "HH:mm" }}
                </div>
                <div class="lh-1">{{ (assignmentDetailsStateService.assignmentDetails | async)?.to | date: "HH:mm" }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
  
        <!-- Location -->
        <div>
          <div class="d-flex flex-column gap-2">
            <div *ngFor="let location of (assignmentDetailsStateService.assignmentDetails | async)?.locations">
              <div class="lh-1">{{ location.facility }} | {{ location.room }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <button (click)="openCancellationModal()" class="btn btn-default w-100">{{'assignments.information.cancel' | transloco}}</button>
    </div>
  </div>
</ng-container>
