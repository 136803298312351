<pmt-action-modal [title]="'assignments.assignmentsFilterModal.title' | transloco" [onlyCancel]="false"
  [cancelButtonText]="'assignments.assignmentsFilterModal.cancelButton' | transloco"
  [submitButtonText]="'assignments.assignmentsFilterModal.submitButton'" [submitButtonIconCode]="''"
  (submitted)="saveForm()">
  <div class="d-flex flex-column gap-2" style="max-height: calc(100vh - 250px); overflow-y: auto;">
    <form [formGroup]="filterForm" (ngSubmit)="saveForm()">
       <div class="container d-flex flex-column gap-3">
        <div class="d-flex flex-column gap-3">
          <app-page-divider
            [text]="'assignments.assignmentsFilterModal.searchAndLevel.title'| transloco" [icon]="'fa-solid fa-magnifying-glass'"></app-page-divider>

          <!-- Filter Text Input -->
          <div>
            <label class="fw-bold" for="freeText">
              {{"assignments.assignmentsFilterModal.searchAndLevel.search" | transloco}}
            </label>
            <div class="input-group">
              <input id="freeText" type="text" formControlName="searchText" [placeholder]="'assignments.assignmentsFilterModal.search'| transloco"
                class="form-control" />
              <button class="btn btn-sm btn-default" type="button" (click)="filterForm.get('searchText')?.reset()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <small class="form-text text-muted">
              {{"assignments.assignmentsFilterModal.searchAndLevel.searchInfoText" | transloco}}
            </small>
          </div>

          <!-- Skill Level Input -->
          <div>
            <label class="fw-bold" for="skillLevel">
              {{"assignments.assignmentsFilterModal.searchAndLevel.level" | transloco}}
            </label>
            <div class="input-group">
              <input id="skillLevel" type="text" [placeholder]="'assignments.assignmentsFilterModal.search'| transloco" formControlName="skillLevel"
                class="form-control" />
              <button class="btn btn-sm btn-default" type="button" (click)="filterForm.get('skillLevel')?.reset()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <small class="form-text text-muted">
              {{"assignments.assignmentsFilterModal.searchAndLevel.levelInfoText" | transloco}}
            </small>
          </div>
        </div>

        <!-- Time Period Input -->
        <app-page-divider [text]="'assignments.assignmentsFilterModal.timePeriod.title' | transloco" [icon]="'fa-solid fa-clock'"></app-page-divider>
        <div class="d-flex flex-column gap-3">
          <div>
            <label class="fw-bold" for="dateFrom">
              {{"assignments.assignmentsFilterModal.timePeriod.from" | transloco}}
            </label>
            <div class="input-group">
              <div class="flex-grow-1">
                <pmt-datepicker customTodayClass="bg-primary-subtle" id="dateFrom" formControlName="dateFrom"
                  placement="top"></pmt-datepicker>
              </div>
              <button class="btn btn-sm btn-default" type="button" (click)="filterForm.get('dateFrom')?.reset()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
          <div>
            <label class="fw-bold" for="dateTo">
              {{"assignments.assignmentsFilterModal.timePeriod.to" | transloco}}
            </label>
            <div class="input-group">
              <div class="flex-grow-1">
                <pmt-datepicker customTodayClass="bg-primary-subtle" id="dateTo" formControlName="dateTo"
                  placement="top"></pmt-datepicker>
              </div>
              <button class="btn btn-sm btn-default" type="button" (click)="filterForm.get('dateTo')?.reset()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <label class="fw-bold" for="show-weekdays">
              {{"assignments.assignmentsFilterModal.filterWeekdays" | transloco}}
            </label>
            <div class="form-check form-switch">
              <input id="show-weekdays" type="checkbox" class="form-check-input"
                formControlName="isWeekdayFilterActive" />
            </div>
          </div>
        </div>

        <!-- Week Days Input (Bootstrap Switches) -->
        <ng-container *ngIf="filterForm.get('isWeekdayFilterActive')?.value">
          <div>
            <app-page-divider
              [text]="'assignments.assignmentsFilterModal.weekdays.title' | transloco" [icon]="'fa-solid fa-calendar'"></app-page-divider>
            <div [formGroup]="filterForm">
              <div formGroupName="weekdays">
                <!-- Monday -->
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <label class="fw-bold me-auto" for="weekday-monday">
                    {{"assignments.assignmentsFilterModal.weekdays.monday" | transloco}}
                  </label>
                  <div class="form-check form-switch">
                    <input id="weekday-monday" type="checkbox" class="form-check-input"
                      formControlName="isMondayActive" />
                  </div>
                </div>

                <!-- Tuesday -->
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <label class="fw-bold me-auto" for="weekday-tuesday">
                    {{"assignments.assignmentsFilterModal.weekdays.tuesday" | transloco}}
                  </label>
                  <div class="form-check form-switch">
                    <input id="weekday-tuesday" type="checkbox" class="form-check-input"
                      formControlName="isTuesdayActive" />
                  </div>
                </div>

                <!-- Wednesday -->
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <label class="fw-bold me-auto" for="weekday-wednesday">
                    {{"assignments.assignmentsFilterModal.weekdays.wednesday" | transloco}}
                  </label>
                  <div class="form-check form-switch">
                    <input id="weekday-wednesday" type="checkbox" class="form-check-input"
                      formControlName="isWednesdayActive" />
                  </div>
                </div>

                <!-- Thursday -->
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <label class="fw-bold me-auto" for="weekday-thursday">
                    {{"assignments.assignmentsFilterModal.weekdays.thursday" | transloco}}
                  </label>
                  <div class="form-check form-switch">
                    <input id="weekday-thursday" type="checkbox" class="form-check-input"
                      formControlName="isThursdayActive" />
                  </div>
                </div>

                <!-- Friday -->
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <label class="fw-bold me-auto" for="weekday-friday">
                    {{"assignments.assignmentsFilterModal.weekdays.friday" | transloco}}
                  </label>
                  <div class="form-check form-switch">
                    <input id="weekday-friday" type="checkbox" class="form-check-input"
                      formControlName="isFridayActive" />
                  </div>
                </div>

                <!-- Saturday -->
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <label class="fw-bold me-auto" for="weekday-saturday">
                    {{"assignments.assignmentsFilterModal.weekdays.saturday" | transloco}}
                  </label>
                  <div class="form-check form-switch">
                    <input id="weekday-saturday" type="checkbox" class="form-check-input"
                      formControlName="isSaturdayActive" />
                  </div>
                </div>

                <!-- Sunday -->
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <label class="fw-bold me-auto" for="weekday-sunday">
                    {{"assignments.assignmentsFilterModal.weekdays.sunday" | transloco}}
                  </label>
                  <div class="form-check form-switch">
                    <input id="weekday-sunday" type="checkbox" class="form-check-input"
                      formControlName="isSundayActive" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- Button -->
        <div>
          <button type="button" class="btn btn-sm btn-default w-100" (click)="clearForm()">
            {{"assignments.assignmentsFilterModal.clearButton" | transloco}}
          </button>
        </div>
      </div>
    </form>
  </div>
</pmt-action-modal>