import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { AssignmentDetailsStateService } from './services/assignment-details-state.service';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-assigment-details',
  templateUrl: './assignment-details.component.html',
  styleUrl: './assignment-details.component.scss'
})
export class AssigmentDetailsComponent implements OnInit {
  assignmentId!: number;
  informationLink: string | null = null;


  constructor(
    private route: ActivatedRoute,
    private headerStateService: HeaderStateService,
    protected assignmentDetailsStateService: AssignmentDetailsStateService,
    private translocoService: TranslocoService
  ) {
    this.headerStateService.setDetailHeader(
      this.translocoService.translate('main.header.assignmentDetailsTitle'),
      this.translocoService.translate('main.header.assignmentDetailsDescription'),
      '/assignments/my',
      'fa-solid fa-arrow-up-right-from-square text-primary');
  }

  ngOnInit(): void {
    this.registerEvents();
    this.route.params.subscribe(params => {
      this.assignmentId = params['id'];
      this.assignmentDetailsStateService.loadAssingmentDetails(this.assignmentId);
    });
  }

  // Header icon action
  private registerEvents(): void {
    this.headerStateService.getActionClickedEvent().subscribe((x) => {
      // Navigate to link logic
      if (!this.informationLink) return;
      window.open(this.informationLink!, '_blank');
    })
  }
}
