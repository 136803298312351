import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CourseDetailsService } from "./assignment-details.service";
import { AssignmentDetailsModel } from "../models/assignment-details.model";

@Injectable({
    providedIn: 'root'
})

export class AssignmentDetailsStateService {
    public assignmentDetails = new BehaviorSubject<AssignmentDetailsModel | null>(null);

    constructor(private CourseDetailsService: CourseDetailsService) { }

    public loadAssingmentDetails(assignmentId: number): void {
        console.log('Loading data for Id: {id}', assignmentId);

        this.CourseDetailsService.getAssignmentDetails(assignmentId).subscribe({
            next: assignmentDetails => {
                this.assignmentDetails.next(assignmentDetails);
            },
            error: (error) => {
            }
        })
    }
}
