import { Component } from '@angular/core';
import { AssignmentParticipantsStateService } from '../../../services/assignments-participants-state.service';

@Component({
  selector: 'app-assignment-participants-list',
  templateUrl: './assignment-participants-list.component.html',
  styleUrl: './assignment-participants-list.component.scss'
})
export class AssignmentParticipantsListComponent {
  isAssigmentSubjectToPayment: boolean = false; // We need this property if a course requires payment

  constructor(protected assignmentParticipantStateService: AssignmentParticipantsStateService) { }
}
