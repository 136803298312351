import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsCommunicationService } from '../../../services/assignments-communication.service';
import { AssignmentCommunicationFeedbackPostModel } from '../../../models/assignment-communication-feedback-post.model';
import { AssignmentsCommunicationStateService } from '../../../services/assignments-communication-state-service';
import { AssignmentChangeCommunicationResultModel } from '../../../models/assignment-change-communication-result.model';

@Component({
  selector: 'app-assignment-communication-feedback-modal',
  templateUrl: './assignment-communication-feedback-modal.component.html',
  styleUrl: './assignment-communication-feedback-modal.component.scss'
})
export class AssignmentCommunicationFeedbackModalComponent implements OnInit {
  feedbackForm!: FormGroup;
  feedbackResponse: AssignmentChangeCommunicationResultModel | null = null;
  assignmentCommunicationFeedbackModel!: AssignmentCommunicationFeedbackPostModel;
  assignmentId!: number;
  errorMessage: string | null = null;


  constructor(
    protected assignmentCommunicationStateService: AssignmentsCommunicationStateService,
    private assignmentCommunicationService: AssignmentsCommunicationService,
    private formBuilder: FormBuilder,
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  public saveForm(): void {
    if (this.feedbackForm.valid) {
      this.sendAssignmentCommunicationFeedback();
    }
  }

  private sendAssignmentCommunicationFeedback(): void {
    this.errorMessage = null;

    this.assignmentCommunicationFeedbackModel = { feedback: this.feedbackForm.value.feedback };
    this.assignmentCommunicationService.sendAssignmentCommunicationFeedback(this.assignmentId, this.assignmentCommunicationFeedbackModel)
      .subscribe({
        next: (response) => {
          this.feedbackResponse = response;
          if (response.isSuccess) this.bsModalRef.hide();
        },
        error: (error) => {
        }
      });
  }

  private initForm(): void {
    this.feedbackForm = this.formBuilder.group({
      feedback: ['', Validators.required]
    })
  }
}
