<ng-container>
  <span class="badge badge-default p-2 d-flex gap-1">
    <span class="text-truncate filter-display-item-text">
      {{visibleText}}
    </span>
    <span *ngIf="overflowCount > 0">
      +{{ overflowCount }}
    </span>
    <div role="button" (click)="deleteFilter()">
      <i class="fa-solid fa-xmark"></i>
    </div>
  </span>
</ng-container>