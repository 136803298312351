import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';
import { AssignmentsFilterModel } from '../models/assignments-filter.model';
import { AssignmentTakeoverModel } from '../models/open-assignments-takeover.model';
import { AssignmentChangeSubstituteResultModel } from '../models/assignment-change-substitute-result.model';
import { AssignmentSubstituteModel } from '../models/assignments-substitute.model';
import { AssignmentCancellationResultModel } from '../assignment-details/models/assignment-cancellation-result.model';
import { AssignmentCancellationPostModel } from '../assignment-details/models/assignment-cancellation-post.model';

@Injectable({
    providedIn: 'root',
})
export class AssignmentsService {
    constructor(private httpClient: HttpClient) { }

    getPastAssignments(filterModel: AssignmentsFilterModel | null): Observable<AssignmentsOverviewModel[]> {
        const params = filterModel != null ? new HttpParams({ fromObject: this.buildQueryParams(filterModel) }) : new HttpParams();
        return this.httpClient.get<AssignmentsOverviewModel[]>(
            `${environment.api}/assignments/my/past`, { params }
        );
    }

    getUpcomingAssignments(filterModel: AssignmentsFilterModel | null): Observable<AssignmentsOverviewModel[]> {
        const params = filterModel != null ? new HttpParams({ fromObject: this.buildQueryParams(filterModel) }) : new HttpParams();
        return this.httpClient.get<AssignmentsOverviewModel[]>(
            `${environment.api}/assignments/my`, { params });
    }


    getOpenAssignments(filterModel: AssignmentsFilterModel | null): Observable<AssignmentsOverviewModel[]> {
        const params = filterModel != null ? new HttpParams({ fromObject: this.buildQueryParams(filterModel) }) : new HttpParams();
        return this.httpClient.get<AssignmentsOverviewModel[]>(
            `${environment.api}/assignments/open`, { params });
    }

    getTopThreeNextAssignments(): Observable<AssignmentsOverviewModel[]> {
        return this.httpClient.get<AssignmentsOverviewModel[]>(
            `${environment.api}/assignments/my?take=3`);
    }

    getTopThreeOpenAssignments(): Observable<AssignmentsOverviewModel[]> {
        return this.httpClient.get<AssignmentsOverviewModel[]>(
            `${environment.api}/assignments/open?take=3`
        );
    }

    sendTakeoverAssignments(takeoverAssignments: AssignmentTakeoverModel): Observable<AssignmentChangeSubstituteResultModel[]> {
        return this.httpClient.post<AssignmentChangeSubstituteResultModel[]>(`${environment.api}/assignments/takeOver`, takeoverAssignments);
    }

    sendSubstituteAssignments(substituteAssignments: AssignmentSubstituteModel): Observable<AssignmentChangeSubstituteResultModel[]> {
        return this.httpClient.post<AssignmentChangeSubstituteResultModel[]>(`${environment.api}/assignments/setSubstitute`, substituteAssignments);
    }

    cancelAssignment(assignmentId: number, assignmentCancellation: AssignmentCancellationPostModel): Observable<AssignmentCancellationResultModel> {
        return this.httpClient.post<AssignmentCancellationResultModel>(`${environment.api}/assignments/${assignmentId}/cancel`, assignmentCancellation);
    }

    private buildQueryParams(filterModel?: AssignmentsFilterModel): { [param: string]: string | boolean } {
        const params: { [key: string]: string | boolean } = {};

        if (!filterModel) return params;

        if (filterModel.dateFrom) params['From'] = filterModel.dateFrom;
        if (filterModel.dateTo) params['To'] = filterModel.dateTo;
        if (filterModel.searchText) params['Search'] = encodeURIComponent(filterModel.searchText.substring(0, 50));
        if (filterModel.skillLevel) params['Level'] = encodeURIComponent(filterModel.skillLevel.substring(0, 50));

        if (filterModel.weekdays) {
            if (filterModel.weekdays.isMondayActive) params['Mo'] = true;
            if (filterModel.weekdays.isTuesdayActive) params['Tu'] = true;
            if (filterModel.weekdays.isWednesdayActive) params['We'] = true;
            if (filterModel.weekdays.isThursdayActive) params['Th'] = true;
            if (filterModel.weekdays.isFridayActive) params['Fr'] = true;
            if (filterModel.weekdays.isSaturdayActive) params['Sa'] = true;
            if (filterModel.weekdays.isSundayActive) params['Su'] = true;
        }
        return params;
    }
}
