import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TrainerModel } from '../models/trainer.model';


@Injectable({
    providedIn: 'root',
})
export class TrainerService {
    constructor(private httpClient: HttpClient) { }

    getTrainers(sportId: number): Observable<TrainerModel[]> {
        return this.httpClient.get<TrainerModel[]>(
            `${environment.api}/trainers/sports/${sportId}`
        );
    }

}