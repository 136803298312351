import { Component, EventEmitter, Output } from '@angular/core';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';
import { AssignmentTakeoverModel } from '../models/open-assignments-takeover.model';
import { AssignmentChangeSubstituteResultModel } from '../models/assignment-change-substitute-result.model';
import { AssignmentsService } from '../services/assignments.service';


@Component({
  selector: 'app-open-assignments-modal',
  templateUrl: './open-assignments-modal.component.html',
  styleUrl: './open-assignments-modal.component.scss'
})
export class OpenAssignmentsModalComponent {
  @Output() onSuccess = new EventEmitter<boolean>();
  isCloseOnly: boolean = false;
  selectedAssignments: AssignmentsOverviewModel[] = [];
  takeoverAssignments?: AssignmentTakeoverModel;

  constructor(
    private assignmentService: AssignmentsService
  ) { }

  public sendTakeoverAssignments(takeoverAssignments: AssignmentTakeoverModel): void {
    this.assignmentService.sendTakeoverAssignments(takeoverAssignments).subscribe(
      (response: AssignmentChangeSubstituteResultModel[]) => {
        response.forEach((resultModel) => {
          const matchingAssignment = this.selectedAssignments.
            find((assignment) => assignment.id == resultModel.assignmentId);
          if (matchingAssignment) {
            matchingAssignment.isSuccess = resultModel.isSuccessful;
            matchingAssignment.errorMessage = resultModel.errorMessage;
          }
        });
        this.onSuccess.emit(true);
      }
    )
    this.isCloseOnly = true;
  }

  protected setTakeoverModel(): void {
    this.takeoverAssignments = this.createAssignmentsTakeoverModel();
    this.sendTakeoverAssignments(this.takeoverAssignments);
  }

  // ToDo: Ask if turned into a shared service makes sense. used by 6 other components - In Assignment Service (BA)
  protected isAssignmentOnSameDay(from: Date | undefined, to: Date | undefined): boolean {
    if (!from || !to) return false;
    return new Date(from).toDateString() === new Date(to).toDateString();
  }

  private createAssignmentsTakeoverModel(): AssignmentTakeoverModel {
    return {
      assignmentIds: this.selectedAssignments
        .map(assignment => assignment.id)
        .filter(id => id !== undefined)
    };
  }
}
