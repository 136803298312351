import { Component, Input, OnInit } from '@angular/core';
import { AssignmentParticipantsStateService } from '../../services/assignments-participants-state.service';

@Component({
  selector: 'app-assignment-participant',
  templateUrl: './assignment-participants.component.html',
  styleUrl: './assignment-participants.component.scss'
})
export class AssignmentParticipantsComponent implements OnInit {
  @Input({required: true}) assignmentId!: number;

  constructor(protected assignmentParticipantStateService: AssignmentParticipantsStateService) {}

  ngOnInit(): void {
    this.assignmentParticipantStateService.loadAssignmentParticipants(this.assignmentId);
  }
}
