import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-filter-display-item',
  templateUrl: './filter-display-item.component.html',
  styleUrl: './filter-display-item.component.scss'
})
export class FilterDisplayItemComponent implements OnInit {
  @Input() items!: BehaviorSubject<string[] | null>;
  @Output() deleteClicked = new EventEmitter<boolean>();

  visibleText?: string | null;
  overflowCount: number = 0;

  private maxVisibleChars: number = 22;

  constructor() { }

  ngOnInit(): void {
    this.registerEvents();
  }

  protected deleteFilter(): void {
    this.deleteClicked.emit(true);
  }

  private registerEvents(): void {
    this.items.subscribe({
      next: items => {
        this.updateVisibleItems(items);
      },
    })
  }

  private updateVisibleItems(items: string[] | null): void {
    // default values
    this.visibleText = null;
    this.overflowCount = 0;

    if (items === null || items.length == 0) return;

    let visibleText = items[0];
    let usedItemsCount = 1;
    const separator = ', ';


    // ToDo: Check performance
    for (let i = 1; i < items.length; i++) {
      if (visibleText.length + items[i].length + separator.length > this.maxVisibleChars) {
        this.overflowCount++;
        break;
      }

      visibleText += (separator + items[i]);
      usedItemsCount++;
    }

    this.overflowCount = items.length - usedItemsCount;
    this.visibleText = visibleText;
  }

}
