import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AssignmentRoutingModule } from './assignment-routing.module';
import { OpenAssignmentsComponent } from './open-assignments/open-assignments.component';
import { PastAssignmentsComponent } from './past-assignments/past-assignments.component';
import { ExchangeAssignmentsComponent } from './exchange-assignments/exchange-assignments.component';
import { MyAssignmentsComponent } from './my-assignments/my-assignments.component';
import { AssignmentsLayoutComponent } from './assignments-layout/assignments-layout.component';
import { AssignmentsPageComponent } from './assignments-page/assignments-page.component';
import { AssignmentSummaryModalComponent } from './assignment-summary-modal/assignment-summary-modal.component';
import { AssignmentsFilterModalComponent } from './assignments-filter-modal/assignments-filter-modal.component';
import { FilterDisplayComponent } from './filter-display/filter-display.component';
import { FilterDisplayItemComponent } from './filter-display-item/filter-display-item.component';
import { ExchangeAssignmentsModalComponent } from './exchange-assignments-modal/exchange-assignments-modal.component';
import { OpenAssignmentsModalComponent } from './open-assignments-modal/open-assignments-modal.component';
import { AssigmentDetailsComponent } from './assignment-details/assignment-details.component';
import { AssignmentAttendanceComponent } from './assignment-details/components/assignment-attendance/assignment-attendance.component';
import { AssignmentCommunicationComponent } from './assignment-details/components/assignment-communication/assignment-communication.component';
import { AssignmentInformationComponent } from './assignment-details/components/assignment-information/assignment-information.component';
import { AssignmentParticipantsComponent } from './assignment-details/components/assignment-participants/assignment-participants.component';
import { AssignmentSportskillsComponent } from './assignment-details/components/assignment-sportskills/assignment-sportskills.component';
import { AssignmentCommunicationFeedbackComponent } from './assignment-details/components/assignment-communication/assignment-communication-feedback/assignment-communication-feedback.component';
import { AssignmentCommunicationFeedbackModalComponent } from './assignment-details/components/assignment-communication/assignment-communication-feedback-modal/assignment-communication-feedback-modal.component';
import { AssignmentCommunicationNotesComponent } from './assignment-details/components/assignment-communication/assignment-communication-notes/assignment-communication-notes.component';
import { AssignmentCommunicationUpdateComponent } from './assignment-details/components/assignment-communication/assignment-communication-update/assignment-communication-update.component';
import { AssignmentCommunicationNotesModalComponent } from './assignment-details/components/assignment-communication/assignment-communication-notes-modal/assignment-communication-notes-modal.component';
import { AssignmentCommunicationUpdateModalComponent } from './assignment-details/components/assignment-communication/assignment-communication-update-modal/assignment-communication-update-modal.component';
import { AssignmentContactParticipantsComponent } from './assignment-details/components/assignment-participants/assignment-contact-participants/assignment-contact-participants.component';
import { AssignmentContactParticipantsModalComponent } from './assignment-details/components/assignment-participants/assignment-participants-contacting-modal/assignment-participants-contacting-modal.component';
import { AssignmentParticipantsListComponent } from './assignment-details/components/assignment-participants/assignment-participants-list/assignment-participants-list.component';
import { AssignmentCourseDataComponent } from './assignment-details/components/assignment-course-data/assignment-course-data.component';
import { AssignmentSportskillsModalComponent } from './assignment-details/components/assignment-sportskills-modal/assignment-sportskills-modal.component';
import { QuillModule } from 'ngx-quill';
import { AssignmentCancelModalComponent } from './assignment-details/components/assignment-information/assignment-cancel-modal/assignment-cancel-modal.component';

@NgModule({
  imports: [
    NgSelectModule,
    CommonModule,
    SharedModule,
    AssignmentRoutingModule,
    QuillModule.forRoot()
  ],
  declarations: [
    AssignmentsLayoutComponent,
    AssignmentsPageComponent,
    ExchangeAssignmentsComponent,
    ExchangeAssignmentsModalComponent,
    OpenAssignmentsComponent,
    OpenAssignmentsModalComponent,
    PastAssignmentsComponent,
    MyAssignmentsComponent,
    AssignmentSummaryModalComponent,
    AssignmentsFilterModalComponent,
    FilterDisplayComponent,
    FilterDisplayItemComponent,
    AssignmentAttendanceComponent,
    AssignmentCommunicationComponent,
    AssignmentInformationComponent,
    AssignmentParticipantsComponent,
    AssignmentSportskillsComponent,
    AssignmentCommunicationFeedbackComponent,
    AssignmentCommunicationFeedbackModalComponent,
    AssignmentCommunicationNotesComponent,
    AssignmentCommunicationUpdateComponent,
    AssignmentCommunicationNotesModalComponent,
    AssignmentCommunicationUpdateModalComponent,
    AssignmentContactParticipantsComponent,
    AssignmentContactParticipantsModalComponent,
    AssignmentParticipantsListComponent,
    AssignmentCourseDataComponent,
    AssigmentDetailsComponent,
    AssignmentSportskillsModalComponent,
    AssignmentCancelModalComponent
  ],
  exports: [],
  providers: [],
})
export class AssignmentModule { }
