import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AssignmentParticipantModel } from "../models/assignment-participant.model";
import { AssignmentsParticipantService } from "./assignments-participants.service";

@Injectable({
    providedIn: 'root'
})
export class AssignmentParticipantsStateService {
    public assignmentParticipantsSubject = new BehaviorSubject<AssignmentParticipantModel[] | null>(null);

    constructor(private assignmentParticipantService: AssignmentsParticipantService) {}

    public loadAssignmentParticipants(assginmentId: number): void {
        this.assignmentParticipantsSubject.next(null);

        this.assignmentParticipantService.getAssignmentParticipants(assginmentId)
        .subscribe({
            next: assigmentParticipant => {
                this.assignmentParticipantsSubject.next(assigmentParticipant);
            }
        })
    }

}