<!-- Loading (This service will be added) -->
<ng-container *ngIf="false">
  <div class="text-center">
    <span><i class="fa-solid fa-spinner fa-spin"></i></span>
  </div>
</ng-container>

<ng-container *ngIf="true">
  <div class="d-flex flex-column" style="height: 4rem"></div>
</ng-container>
