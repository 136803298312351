import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentSportskillsModalComponent } from '../assignment-sportskills-modal/assignment-sportskills-modal.component';
import { AssignmentParticipantsStateService } from '../../services/assignments-participants-state.service';

@Component({
  selector: 'app-assignment-sportskills',
  templateUrl: './assignment-sportskills.component.html',
  styleUrl: './assignment-sportskills.component.scss'
})
export class AssignmentSportskillsComponent implements OnInit {
  @Input({required: true}) assignmentId!: number;
  assignmentParticipantStateService: any;

  constructor(
    private modalRef: BsModalRef,
    private bsModalService: BsModalService,
    protected assignmenParticipantStateService: AssignmentParticipantsStateService
  ) { }

  ngOnInit(): void {
    this.assignmenParticipantStateService.loadAssignmentParticipants(this.assignmentId);
  }

  public openModal(): void {
    this.modalRef = this.bsModalService.show(AssignmentSportskillsModalComponent);
        this.modalRef.content.assignmentsFilterSubject;
  }
}
