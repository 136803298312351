import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssignmentsFilterModel } from '../models/assignments-filter.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { AssignmentStateService } from '../services/assignment-state.service';

@Component({
  selector: 'app-assignments-filter-modal',
  templateUrl: './assignments-filter-modal.component.html',
  styleUrl: './assignments-filter-modal.component.scss'
})
export class AssignmentsFilterModalComponent implements OnInit {
  assignmentsFilterSubject?: BehaviorSubject<AssignmentsFilterModel>;
  assignmentsFilterModel?: AssignmentsFilterModel;
  filterForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private bsModalService: BsModalService,
    private assignmentStateService: AssignmentStateService
  ) { }

  ngOnInit(): void {
    this.initForm();
    if (this.assignmentsFilterSubject) {
      this.loadSavedFilters();
    }
  }

  public saveForm(): void {
    if (!this.filterForm.valid) return;

    const formValue = this.filterForm.value;
    const weekdaysValue = formValue.weekdays;

    const assignmentsFilter: AssignmentsFilterModel = {
      dateFrom: formValue.dateFrom ? new Date(formValue.dateFrom).toLocaleDateString("de-CH") : null,
      dateTo: formValue.dateTo ? new Date(formValue.dateTo).toLocaleDateString("de-CH") : null,
      skillLevel: formValue.skillLevel,
      weekdays: {
        isMondayActive: weekdaysValue.isMondayActive,
        isTuesdayActive: weekdaysValue.isTuesdayActive,
        isWednesdayActive: weekdaysValue.isWednesdayActive,
        isThursdayActive: weekdaysValue.isThursdayActive,
        isFridayActive: weekdaysValue.isFridayActive,
        isSaturdayActive: weekdaysValue.isSaturdayActive,
        isSundayActive: weekdaysValue.isSundayActive
      },
      searchText: formValue.searchText,
      isWeekdayFilterActive: formValue.isWeekdayFilterActive
    };

    if (this.assignmentsFilterSubject) {
      this.assignmentsFilterSubject.next(assignmentsFilter);
    }

    this.bsModalService.hide();
  }

  public clearForm(): void {
    this.filterForm.setValue(
      this.assignmentStateService.setAssignmentFilterDefault()
    )
  }

  private initForm(): void {
    this.filterForm = this.formBuilder.group({
      searchText: [''],
      skillLevel: [''],
      dateFrom: [''],
      dateTo: [''],
      weekdays: this.formBuilder.group({
        isMondayActive: [true],
        isTuesdayActive: [true],
        isWednesdayActive: [true],
        isThursdayActive: [true],
        isFridayActive: [true],
        isSaturdayActive: [true],
        isSundayActive: [true]
      }),
      isWeekdayFilterActive: [false]
    });

    // ToDo: Remove once filter is implemented
    this.filterForm.controls['skillLevel'].disable();
  }

  private loadSavedFilters(): void {
    if (this.assignmentsFilterSubject) {
      this.assignmentsFilterSubject.subscribe(savedFilter => {
        if (savedFilter !== undefined) {
          const weekdays = savedFilter.weekdays!;

          this.filterForm.patchValue({
            searchText: savedFilter.searchText,
            skillLevel: savedFilter.skillLevel,
            dateFrom: savedFilter.dateFrom,
            dateTo: savedFilter.dateTo,
            weekdays: {
              isMondayActive: weekdays.isMondayActive,
              isTuesdayActive: weekdays.isTuesdayActive,
              isWednesdayActive: weekdays.isWednesdayActive,
              isThursdayActive: weekdays.isThursdayActive,
              isFridayActive: weekdays.isFridayActive,
              isSaturdayActive: weekdays.isSaturdayActive,
              isSundayActive: weekdays.isSundayActive
            },
            isWeekdayFilterActive: savedFilter.isWeekdayFilterActive
          });
        }
      });
    }
  }
}
