import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DateHelper {
    public IsOnSameDay(from: Date, to: Date): boolean {
        var result = new Date(from).getDate() === new Date(to).getDate();
        return result;
    }
}