<div class="d-flex flex-column gap-3">
  <button
    (click)="openSubstituteModal()"
    class="btn btn-primary"
    [disabled]="selectedAssignments?.length === 0"
  >
    {{ selectedAssignments?.length! > 0 ? selectedAssignments?.length : 0 }}
    {{ "assignments.exchangeAssignments.title" | transloco }}
  </button>

  <app-filter-display
    [filterModelSubject]="assignmentsStateService.exchangeAssignmentsFilterSubject"
  ></app-filter-display>

  <app-assignments-list
    [eventType]="assignmentEventType.Checkbox"
    [assignmentSubject]="assignmentStateService.upcomingAssignmentsSubject"
    (assignmentsSelected)="onAssignmentSelected($event)"
  ></app-assignments-list>
</div>
