<pmt-action-modal 
  [title]="'assignments.exchangeAssignments.title' | transloco" 
  [onlyCancel]="isCloseOnly"
  [cancelButtonText]="isCloseOnly ? ('modal.closeButton' | transloco) : ('modal.cancelButton' | transloco)"
  [submitButtonText]="'assignments.exchangeAssignments.submitButton' | transloco" 
  [submitButtonIconCode]="''"
  (submitted)="setSubstituteModel()"
  >
  <div class="container d-flex flex-column gap-3">
    <div *ngFor="let assignment of selectedAssignments; let i = index">
      <div class="text-decoration-none text-dark w-100 shadow p-3 d-block" [ngClass]="{
          'border border-2 border-success': assignment.isSuccess == true,
          'border border-2 border-danger': assignment.isSuccess == false
        }">
        <div class="d-flex align-items-center gap-1 w-100">
          <div class="flex-grow-1 overflow-hidden text-start">
            <div class="d-flex align-items-center">
              <i class="fa-regular fa-calendar-days me-1"></i>
              <span class="mb-0 fs-6 fw-lighter text-start text-nowrap">
                {{ assignment.from | date: "EE dd.MM.yy | HH:mm" }}
              </span>
              <span> - </span>
              <span class="mb-0 fs-6 fw-lighter text-start text-nowrap">
                <ng-container *ngIf="
                      isAssignmentOnSameDay(assignment.from, assignment.to) else fullDate">
                  {{ assignment.to | date: "HH:mm" }}
                </ng-container>
                <ng-template #fullDate>
                  {{ assignment.to | date: "EE dd.MM.yy | HH:mm" }}
                </ng-template>
              </span>
            </div>
            <div class="mb-0 fw-bold text-truncate">
              {{ assignment.sportName }} | {{ assignment.title }}
            </div>
            <p class="mb-0 fs-6">{{ assignment.room }}</p>
          </div>
        </div>
        <!-- Individual Response Message -->
        <div class="text-danger" *ngIf="assignment.isSuccess == false">
          {{ assignment.errorMessage }}
        </div>
      </div>
    </div>
    <form [formGroup]="exchangeAssignmentsForm">
      <div>
        <ng-select formControlName="trainerSelect" [required]="true" [(ngModel)]="selectedTrainer" [searchable]="true"
          [clearable]="true" [placeholder]="'TL suchen'">
          <ng-option *ngFor="let trainer of trainers$ | async" [value]="trainer.id">
            {{(trainer.firstName + ' ' + trainer.lastName).trim()}}
          </ng-option>
        </ng-select>
      </div>
    </form>
  </div>
</pmt-action-modal>