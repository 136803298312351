<pmt-action-modal
  [title]="'assignments.communication.editNotes' | transloco"
  [cancelButtonText]="'modal.cancelButton' | transloco"
  submitButtonIconCode="''"
  (submitted)="saveForm()"
>
  <!-- Loading -->
  <ng-container *ngIf="!noteForm">
    <div class="text-center">
      <span>
        <i class="fa-solid fa-spinner fa-spin"></i>
      </span>
    </div>
  </ng-container>

  <!-- Loading Finished -->
  <ng-container *ngIf="noteForm">
    <div [ngClass]="{'border border-2 border-danger': noteResponse?.isSuccess == false}">
      <form [formGroup]="noteForm">
        <textarea
          class="form-control"
          formControlName="note"
          rows="3"
          [disabled]="isLoading"
        ></textarea>
        <small class="form-text text-muted">{{'assignments.communication.editUpdateInfoText' | transloco}}</small>
      </form>
    </div>
  <ng-container *ngIf="!noteResponse?.isSuccess">
    <p class=" text-danger">{{noteResponse?.errorMessage}}</p>
  </ng-container>
  </ng-container>
</pmt-action-modal>
