import { Component } from '@angular/core';
import { AssignmentDetailsStateService } from '../../services/assignment-details-state.service';
import { DateHelper } from '../../../../shared/helpers/date.helper';
import { AssignmentDetailsDateModel } from '../../models/assignment-details-date.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentCancelModalComponent } from './assignment-cancel-modal/assignment-cancel-modal.component';

@Component({
  selector: 'app-assignment-information',
  templateUrl: './assignment-information.component.html',
  styleUrl: './assignment-information.component.scss'
})
export class AssignmentInformationComponent {

  constructor(
    protected assignmentDetailsStateService: AssignmentDetailsStateService,
    private dateHelper: DateHelper,
    private bsModalService: BsModalService,
    private bsModalRef: BsModalRef
  ) {
  }

  protected IsOnSameDay(assignmentDetailsModel: AssignmentDetailsDateModel | null): boolean {
    if (assignmentDetailsModel == null) return false;
    return this.dateHelper.IsOnSameDay(assignmentDetailsModel.from, assignmentDetailsModel.to);
  }

  protected openCancellationModal(): void {
    this.bsModalRef = this.bsModalService.show(AssignmentCancelModalComponent, {
      initialState: {
        assignmentId: this.assignmentDetailsStateService.assignmentDetails.value?.id
      }
    });
  }
}
