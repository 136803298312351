<pmt-action-modal
  [title]="'assignments.skills.modalTitle'| transloco"
  submitButtonText="modal.saveButton"
  cancelButtonText="modal.cancelButton"
  submitButtonIconCode=""
  (submitted)="saveForm()"
>
  <ng-container
    *ngIf="assignmentParticipantStateService.assignmentParticipantsSubject | async as participants"
  >
    <form [formGroup]="skillsForm">
      <table class="w-100">
        <thead>
          <tr>
            <th class="text-left">{{ 'assignments.skills.participants'| transloco }}</th>
            <th *ngFor="let skill of skills; let i = index" class="text-center">
              <div class="d-flex flex-column align-items-center">
                <strong>{{ skill }}</strong>
                <button
                  type="button"
                  class="btn btn-primary btn-sm mt-2"
                  (click)="selectAll('skillColumn' + (i + 1))"
                >
                {{ 'assignments.skills.selectAll'| transloco }}
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody formArrayName="participants">
          <tr *ngFor="let participant of participants; let i = index" [formGroupName]="i">
            <td class="text-left">
              {{ participant.firstName }} {{ participant.lastName }}
            </td>
            <td *ngFor="let skillIndex of skillKeys; let j = index" class="text-center">
              <input
                type="checkbox"
                class="form-check-input"
                [formControlName]="'skillColumn' + (j + 1)"
                [ngClass]="{
                  'checked-blue': skillsForm.value.participants[i]?.['skillColumn' + (j + 1)],
                  'unchecked-red': !skillsForm.value.participants[i]?.['skillColumn' + (j + 1)]
                }"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </ng-container>
</pmt-action-modal>
