<ng-container *ngIf="assignmentsFilterStateService.isAnyFilterDisplayed">
  <div class="d-flex flex-row gap-1 pb-3 flex-wrap">
    <button class="badge badge-default p-2" (click)="resetFilterDisplayItems()">
      {{"assignments.assignmentsFilterModal.clearButton" | transloco}}
    </button>

    <ng-container *ngIf="(assignmentsFilterStateService.searchTextItems | async)?.length">
      <app-filter-display-item [items]="assignmentsFilterStateService.searchTextItems"
        (deleteClicked)="assignmentsFilterStateService.deleteSearchFilter(filterModelSubject)">
      </app-filter-display-item>
    </ng-container>

    <ng-container *ngIf="(assignmentsFilterStateService.skillLevelItems | async)?.length">
      <app-filter-display-item [items]="assignmentsFilterStateService.skillLevelItems"
        (deleteClicked)="assignmentsFilterStateService.deleteSkillLevelFilter (filterModelSubject)">
      </app-filter-display-item>
    </ng-container>

    <ng-container *ngIf="(assignmentsFilterStateService.dateItems | async)?.length">
      <app-filter-display-item [items]="assignmentsFilterStateService.dateItems"
        (deleteClicked)="assignmentsFilterStateService.deleteDateRangeFilter(filterModelSubject)">
      </app-filter-display-item>
    </ng-container>

    <ng-container *ngIf="(assignmentsFilterStateService.weekdaysItems | async)?.length">
      <app-filter-display-item [items]="assignmentsFilterStateService.weekdaysItems"
        (deleteClicked)="assignmentsFilterStateService.deleteWekdaysFilter (filterModelSubject)">
      </app-filter-display-item>
    </ng-container>

  </div>
</ng-container>