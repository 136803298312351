import { Component } from '@angular/core';
import { AssignmentDetailsStateService } from '../../services/assignment-details-state.service';
import { DateHelper } from '../../../../shared/helpers/date.helper';
import { AssignmentDetailsDateModel } from '../../models/assignment-details-date.model';

@Component({
  selector: 'app-assignment-course-data',
  templateUrl: './assignment-course-data.component.html',
  styleUrl: './assignment-course-data.component.scss'
})
export class AssignmentCourseDataComponent {
  constructor(
    protected dateHelper: DateHelper,
    protected assignmentDetailsStateService: AssignmentDetailsStateService) { }

  protected IsOnSameDay(assignmentDetailsModel: AssignmentDetailsDateModel | null): boolean {
    if (assignmentDetailsModel == null) return false;
    return this.dateHelper.IsOnSameDay(assignmentDetailsModel.from, assignmentDetailsModel.to);
  }
}
